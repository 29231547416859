@import '../../../styles/customMediaQueries.css';

.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #ffffff
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.main {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 24px;

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 48px 36px 82px 59px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 48px calc((100% - 1056px) / 2) 82px 59px;
  }
}

.sideNav {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100vw;
  padding: 0 24px;

  border-top-width: 1px;
  border-top-color: var(--colorGrey100);
  border-top-style: solid;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    padding: 48px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    width: unset;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 48px 0 82px calc((100% - 1056px) / 2);
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px;

  @media (--viewportMedium) {
    min-height: 48px;
  }

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 0;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.editListingPageWrapper {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  /* min-height: 100vh; */

  @media (--viewportMedium) {
    padding: 0 24px;
    flex-direction: row;
    margin: 0px auto 60px;
  }

  @media (max-width:768px) {
    padding: 0 24px 24px 24px;
  }

  & .sidebar {
    width: 100%;
    max-width: 250px;
    border: 1px solid #DDDDDD;
    box-shadow: 0px 0px 10px 0px #E0E0E0;
    background-color: var(--colorWhite);
    position: absolute;
    left: -1000px;

    @media (--viewportMedium) {
      position: relative;
      left: 0;
    }

    @media (--viewportLarge) {
      max-width: 300px;
    }

    @media (--viewportLargeWithPaddings) {
      max-width: 340px;
    }

    @media (max-width:768px) {
      width: 100%;
      max-width: 100%;
      background-color: var(--colorWhite);
      position: relative;
      left: 0;
      top: 0;
      padding-bottom: 10px;
      border: none;
      box-shadow: none;
    }

    /* &.sidebarMobile {
      left: 0px;
      z-index: 9;
      height: 100%;
      overflow-y: auto;
      top: 0;
    } */
  }

  & .mainContent {
    @media (--viewportMedium) {
      width: calc(100% - 250px);
      padding: 14px 0 0px 14px;
    }

    @media (--viewportLarge) {
      width: calc(100% - 300px);
      padding: 24px 0 0px 24px;
    }

    @media (--viewportLargeWithPaddings) {
      width: calc(100% - 340px);
    }

    & .pageTitle {
      color: var(--marketplaceColor);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      margin-bottom: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (--viewportMedium) {
        font-size: 15px;
        font-weight: 400;
      }

      & .profileLink {


        & a {
          background-color: var(--marketplaceColor);
          padding: 12px;
          border-radius: 5px;

          & span {
            display: inline-block;
            color: #fff;
            font-family: 'Poppins';
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
            padding: 5px;

          }
        }

        &:hover {
          &>svg {
            stroke: var(--marketplaceSecondaryColor);

            &>g {
              stroke: var(--marketplaceSecondaryColor);
            }
          }
        }
      }

      & .menuIcon {
        display: inline-flex;

        @media (--viewportMedium) {
          display: none;
        }

        & path {
          fill: var(--colorBlack);
        }
      }
    }
  }
}