.root {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* @media (max-width: 1220px) {
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0;
  } */
}
.dashboardSidetab {
  & .tabs {
    padding-bottom: 60px;
    & .tab {
      display: flex;
      width: 100%;
      border-top: 1px solid #0000000d;
      &:last-child {
        border-bottom: 1px solid #0000000d;
      }
    }
  }
}
