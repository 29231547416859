@import '../../styles/customMediaQueries.css';

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: none;

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 29px 24px 0 24px;

  border-top-width: 1px;
  border-top-color: var(--colorGrey100);
  border-top-style: solid;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 24px 24px 0 24px;
  }

  @media (--viewportLarge) {
    flex-wrap: unset;
    padding: 40px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 40px 0 82px calc((100% - 1056px) / 2);
  }
}

.editListingPageWrapper {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  background-color: #ffffff;

  @media (--viewportMedium) {
    padding: 0 24px;
    flex-direction: row;
    margin: 0px auto 60px;
  }

  & .sidebar {
    width: 100%;
    max-width: 250px;
    border: 1px solid #00000017;
    box-shadow: 5px 1px 3px 0px #e0e0e01c;
    background-color: var(--colorWhite);
    position: absolute;
    left: -1000px;

    @media (--viewportMedium) {
      position: relative;
      left: 0;
    }

    @media (--viewportLarge) {
      max-width: 300px;
    }

    @media (--viewportLargeWithPaddings) {
      max-width: 340px;
    }

    &.sidebarMobile {
      left: 0px;
      z-index: 9;
      height: 100%;
      overflow-y: auto;
      top: 0;
    }
  }

  & .mainContent {
    @media (--viewportMedium) {
      width: calc(100% - 250px);
      padding: 14px 0 0px 14px;
    }

    @media (--viewportLarge) {
      width: calc(100% - 300px);
      padding: 24px 0 0px 24px;
    }

    @media (--viewportLargeWithPaddings) {
      width: calc(100% - 340px);
    }

    & .pageTitle {
      color: var(--marketplaceColor);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      margin-bottom: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (--viewportMedium) {
        font-size: 15px;
        font-weight: 400;
      }

      & .menuIcon {
        display: inline-flex;

        @media (--viewportMedium) {
          display: none;
        }

        & path {
          fill: var(--colorBlack);
        }
      }
    }
  }
}

.profileContent {
  border: 1px solid #0000000f;
  background-color: var(--colorWhite);
  padding: 24px;
  float: left;
  width: 100%;

  @media (--viewportLarge) {
    padding: 36px;
  }
}

.title {
  align-self: center;
  padding: 0px 0 8px 0;

  /* Layout */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    padding: 5px 0 3px 0;
    align-self: unset;
  }
}

.tabs {
  padding-top: 2px;

  display: flex;
  flex-direction: row;
  overflow-x: auto;

  @media (--viewportMedium) {
    padding-top: 12px;
    padding-top: 20px;
  }

  @media (--viewportLarge) {
    padding-top: 48px;
    flex-direction: column;
  }
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }

  &>a {
    &>p {
      padding-left: 34px;
    }
  }
}

.error {
  color: var(--colorFail);
  margin: 23px 24px 24px 24px;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.noResults {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 1px;

  @media (--viewportLarge) {
    margin-top: 25px;
  }
}

.itemList {
  margin: 0;
  padding: 0;
}

@keyframes loadingSpinnerFadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.listItemsLoading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;

  /* animation shorthand property doesn't work with local scope of CSS Modules */
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.listItem {
  /* Layout */
  /* margin-bottom: 10px;
  padding-bottom: 12px;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--colorGrey100); */

  &:last-child {
    border-bottom: none;
  }

  /* @media (--viewportLarge) {
    margin-bottom: 16px;
  } */
}

.item {
  /* display: flex;
  flex-direction: row; */
  box-shadow: 0px 4px 30px 0px #00326D1A;
  padding: 18px;
  border-radius: 4px;
  margin-top: 24px;
}

.inboxCard {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media(max-width:500px) {
    flex-direction: column;
    gap: 14px;
  }

  & .itemAvatar {
    display: flex;
    gap: 14px;
    align-items: center;
  }

  .compentationSection {
    display: flex;
    gap: 30px;
  }

  & .profileCompentation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    position: relative;

    /* &::after {
      content: "";
      background-color: #0000004D;
      left: -17px;
      position: absolute;
      width: 2px;
      height: 40px;
      top: 17px;
    } */

    & .leftHeading {
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      text-align: left;
      color: #0000004D;

      @media(max-width:500px) {
        font-size: 14px;
      }
    }

    & .rigthDetail {
      background-color: #00dc495a;
      color: #00AB30;
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: center;
      width: 100%;
      padding: 8px;
      border-radius: 10px;

      @media(max-width:500px) {
        font-size: 14px;
      }
    }
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media(max-width:500px) {
    flex-direction: column;
    gap: 14px;
    align-items: flex-start;
  }

  & .sendButton {
    background-color: #054F31;
    padding: 16px 22px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    color: #ffffff;
    border-radius: 10px;

    @media(max-width:500px) {
      font-size: 14px;
      padding: 12px 18px;
    }
  }
}

.itemAvatar {
  display: flex;
  align-items: center;
  margin-right: 8px;

  @media (--viewportMedium) {
    display: block;
    align-items: unset;
  }

  @media (--viewportLarge) {
    margin-top: 3px;
    margin-right: 7px;
  }
}

.itemLink {
  flex: 1;

  /* Layout */
  display: inline-grid;
  grid-template-columns: 22px auto;
  grid-template-areas:
    'dot name    state'
    '... item    state'
    '... details state';
  padding: 2px 0 6px;
  gap: 4px 0;

  /* Remove text underline from this link */
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:hover .itemUsername {
    /* Hightlight the username when the whole item is hovered */
    color: var(--marketplaceColor);
  }
}

.rowNotificationDot {
  grid-area: dot;
  justify-self: center;
  align-self: flex-start;
  margin-top: 8px;

  width: 6px;
  height: 6px;

  @media (--viewportLarge) {
    margin-top: 5px;
    margin-left: 5px;
  }

  @media (--viewportLarge) {
    justify-self: flex-start;
    width: 8px;
    height: 8px;
  }
}

.notificationDot {
  color: var(--colorWhite);

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--colorFail);
}

.itemUsername {
  grid-area: name;

  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    line-height: 16px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.itemTitle {
  composes: marketplaceTinyFontStyles from global;
  grid-area: item;
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 0;
}

.itemDetails {
  composes: marketplaceTinyFontStyles from global;
  grid-area: details;
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 0;
}

.itemState {
  grid-area: state;

  /* Font */
  composes: marketplaceTinyFontStyles from global;
  line-height: 18px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: normal;

  justify-self: flex-end;
  text-align: end;

  margin-top: 6px;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.pagination {
  margin: auto 24px 0 24px;
}

/* Transaction status affects to certain font colors and weights */

.stateName {
  /* This class is empty on purpose, it is used below for banned users */
}

.stateActionNeeded {
  font-weight: var(--fontWeightMedium);
  color: var(--colorAttention);
}

.stateNoActionNeeded {
  color: var(--colorGrey700);
}

.stateConcluded {
  color: var(--colorGrey300);
}

.bookingActionNeeded {
  color: var(--colorGrey700);
  margin-top: 4px;

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.bookingNoActionNeeded {
  color: var(--colorGrey300);
}

.lastTransitionedAtEmphasized {
  color: var(--colorGrey700);
  font-weight: var(--fontWeightMedium);
}

.lastTransitionedAtNotEmphasized {
  color: var(--colorGrey300);
}

.bannedUserLink {

  & .itemUsername,
  &:hover .itemUsername,
  & .stateName {
    color: var(--colorGrey300);
  }
}

@import '../../styles/customMediaQueries.css';

.root {
  background-color: #ffffff;
}

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    width: 100%;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

& .sidebar {
  width: 100%;
  max-width: 250px;
  border: 1px solid #00000017;
  box-shadow: 5px 1px 3px 0px #e0e0e01c;
  background-color: var(--colorWhite);
  position: absolute;
  left: -1000px;

  @media (--viewportMedium) {
    position: relative;
    left: 0;
  }

  @media (--viewportLarge) {
    max-width: 300px;
  }

  @media (--viewportLargeWithPaddings) {
    max-width: 340px;
  }

  &.sidebarMobile {
    left: 0px;
    z-index: 9;
    height: 100%;
    overflow-y: auto;
    top: 0;
  }
}

.editListingPageWrapper {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  background-color: #ffffff;

  @media (--viewportMedium) {
    padding: 0 24px;
    flex-direction: row;
    margin: 0px auto 60px;
  }

  & .sidebar {
    width: 100%;
    max-width: 250px;
    border: 1px solid #00000017;
    box-shadow: 5px 1px 3px 0px #e0e0e01c;
    background-color: var(--colorWhite);
    position: absolute;
    left: -1000px;

    @media (--viewportMedium) {
      position: relative;
      left: 0;
    }

    @media (--viewportLarge) {
      max-width: 300px;
    }

    @media (--viewportLargeWithPaddings) {
      max-width: 340px;
    }

    &.sidebarMobile {
      left: 0px;
      z-index: 9;
      height: 100%;
      overflow-y: auto;
      top: 0;
    }
  }

  & .mainContent {
    @media (--viewportMedium) {
      width: calc(100% - 250px);
      padding: 14px 0 0px 14px;
    }

    @media (--viewportLarge) {
      width: calc(100% - 300px);
      padding: 24px 0 0px 24px;
    }

    @media (--viewportLargeWithPaddings) {
      width: calc(100% - 340px);
    }

    & .pageTitle {
      color: var(--marketplaceColor);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      margin-bottom: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (--viewportMedium) {
        font-size: 15px;
        font-weight: 400;
      }

      & .menuIcon {
        display: inline-flex;

        @media (--viewportMedium) {
          display: none;
        }

        & path {
          fill: var(--colorBlack);
        }
      }
    }
  }
}

.profileContent {
  border: 1px solid #0000000f;
  background-color: var(--colorWhite);
  padding: 24px;
  float: left;
  width: 100%;

  @media (--viewportLarge) {
    padding: 36px;
  }
}

.BuyTopSection {
  & .buyHeading {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    color: #000;
  }

  & .buySubdetail {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #000;
  }
}

.buttonTopWrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  background-color: #F6F6F6;
  padding: 14px;
  border: 1px solid #f6f6f6;
  margin-top: 10px;
  flex-wrap: wrap;

  & .setButton {
    background-color: #054F31;
    padding: 24px 22px;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    color: #ffffff;
    border-radius: 10px;

    @media(max-width:500px) {
      font-size: 14px;
      padding: 12px 18px;
    }
  }

  & .currentButton {
    border: 1px solid #DDDDDD;
    background-color: #DDDDDD;
    padding: 13px 12px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;

    & .currentCompemtation {
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: left;
      color: #000000;
      border-radius: 5px;

      @media(max-width:500px) {
        font-size: 14px;
      }
    }

    & .compentationPercentage {
      background-color: #054F31;
      color: #fff;
      display: inline-block;
      padding: 10px;
      border-radius: 10px;
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      text-align: left;

      @media(max-width:500px) {
        font-size: 14px;
      }

    }
  }
}

.tabContainer {
  display: flex;
  gap: 30px;
  margin: 18px;
  align-items: center;
  width: 100%;
  overflow-x: auto;

  & .activeTab {
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #054F31;
    padding-bottom: 5px;
    border-bottom: 2px solid #054F31;

    @media(max-width:1024px) {
      font-size: 16px;
    }
  }

  & .inactiveTab {
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: #DDDDDD;
    padding-bottom: 5px;

    @media(max-width:1024px) {
      font-size: 16px;
    }
  }
}

.tabDetails {
  background-color: #ffffff;
  box-shadow: 0px 4px 30px 0px #00326D1A;
  padding: 20px;
  margin-bottom: 20px;
}

.topImageWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  flex-wrap: wrap;

  @media(max-width:768px) {
    gap: 28px;
  }

  & .imageSection {
    display: flex;
    gap: 28px;
    align-items: center;

    @media(min-width:768px) {
      margin-left: 30px;
    }

    & img {
      height: 60px;
      width: 60px;
      border-radius: 100%;
    }

    & .profileName {
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      text-align: center;
      color: #000000;
    }
  }

  & .profileCompentation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    position: relative;

    &::after {
      content: "";
      background-color: #0000004D;
      left: -17px;
      position: absolute;
      width: 2px;
      height: 40px;
      top: 17px;
    }

    & .leftHeading {
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      text-align: left;
      color: #0000004D;

      @media(max-width:500px) {
        font-size: 14px;
      }
    }

    & .rigthDetail {
      background-color: #00dc495a;
      color: #00AB30;
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: center;
      width: 100%;
      padding: 8px;
      border-radius: 10px;

      @media(max-width:500px) {
        font-size: 14px;
      }
    }
  }
}

.providedCompentation {
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  padding: 12px;
  margin-top: 16px;
  border-top: 1px solid #0000001A;
  color: #618741;

  @media(max-width:500px) {
    font-size: 14px;
  }

}

.bioSection {
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: var(--marketplaceColor);
  padding: 6px;
  background: #00326D1A;
  border-radius: 10px;

  @media(max-width:500px) {
    font-size: 14px;
  }
}

.aboutSection {
  padding: 20px 0;
  border-bottom: 1px solid #0000001A;


  & span {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #C2C2C2;
    text-transform: capitalize;

    @media(max-width:500px) {
      font-size: 16px;
    }
  }

  &>div {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #C2C2C2;

    @media(max-width:500px) {
      font-size: 14px;
    }
  }
}

.contactButton {
  background-color: #054F31;
  padding: 12px 18px;
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  color: #ffffff;
  border-radius: 5px;
  width: fit-content;
  margin-top: 20px;

  @media(max-width:500px) {
    font-size: 14px;
  }
}

.spacer {
  margin: 30px 0;
  height: 1px;
  background-color: #DDDDDD;
}

.tickOffer {
  margin-left: 6px;
}

.accepted {
  color: #00AB30;
}

.inquiry {
  color: #ffae00dd;
}

.canceled {
  color: #ff0000;
}