@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  composes: textSmall from global;

  color: var(--colorGrey700);
  padding-bottom: 10px;
  padding-top: 10px;
  height: 100%;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  /* border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent; */

  transition: var(--transitionStyleButton);
  /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
  background-position: calc(var(--TabNav_linkWidth) + 4px) center; */
  /* SelectedLink's width (200px) + border thickness (4px) = 204px */

  &:hover {
    text-decoration: none;
    color: var(--colorBlack);
  }

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    line-height: 24px;

    height: unset;
    /* width: var(--TabNav_linkWidth); */
    width: 100%;
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 16px;

    padding-top: 5px;
    /* Drop the text to adjust it to correct baseline */
    padding-bottom: 3px;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
      background-position: right center;
    }
  }
}

.selectedLink {
  /* border-bottom-color: var(--colorBlack); */
  color: var(--colorBlack);

  @media (--viewportLarge) {
    /* width: var(--TabNav_linkWidth); */
    width: 100%;
    /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
    background-position: right center; */
    color: #fff;
    background-color: #054F31;

    &:hover {
      /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
      background-position: right center; */
      color: #fff;
    }
  }

  /* &>p {
    padding-left: 30px;
  } */
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

.dashboardSideTabNav {
  & .link {
    color: var(--colorLabel);
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0px;
    padding: 10px 14px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    background-image: none;
    margin: 0;

    @media (--viewportLarge) {
      padding: 20px 30px;
    }

    @media (--viewportLargeWithPaddings) {
      padding: 20px 30px 20px 40px;
    }

    &:hover,
    &.selectedLink {
      background-image: none;
      color: #fff;
      background-color: #054F31;

      & svg {
        & path {
          fill: #fff;
        }
      }
    }

    & .icon {
      width: 50px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      & svg {
        @media(max-width:768px) {
          width: 24px;
          height: 24px;
        }
      }
    }

    &>p {
      width: calc(100% - 50px);
      margin: 0;
    }

    /* &::after {
      width: 8px;
      bottom: auto;
      left: auto;
      height: 0;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      transition: var(--transitionStyle);
      border-radius: 100px;
      background-color: var(--marketplaceSecondaryColor);
      content: '';
      position: absolute;
      z-index: 0;
    } */
  }
}