.customField {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.fieldRadioLabel {
  font-size: 18px;
  margin-bottom: 12px;
  font-weight: 500;
}

.radioInput {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @media(max-width:768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 18px;
  }

  &>div {
    &>span {
      & label {
        margin: 0;
        padding: 0;

        &>span {
          font-weight: 400;
        }
      }
    }
  }
}