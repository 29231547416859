.locationInput {
    margin-top: 30px;

    & label {
        font-size: 15px;
        margin-bottom: 12px;
        font-weight: 400;
    }

    &>div {
        &>div {
            &>div {
                width: auto;
                border-bottom: none;

                & svg {
                    display: none;
                }
            }
        }
    }
}

.zipCode {
    margin-top: 30px;

    & label {
        font-size: 15px;
        margin-bottom: 12px;
        font-weight: 400;
    }

    & input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}

.updateButton {
    width: 237px;
    margin-top: 40px;
}
.updateFormWrapper {
    max-height: 800px;
  overflow-y: auto;
  padding: 20px;
  border: 1px solid #0000000f;
  background-color: var(--colorWhite);

  &::-webkit-scrollbar {
    width: 5px;
  }


  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }


  &::-webkit-scrollbar-thumb {
    background: var(--marketplaceColor);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.customFields {
    &>div {
        margin-bottom: 28px;
    }

    & .inputBox {
        margin-top: 28PX;
    }
}
.customField{
    margin-top: 28px;
}

.inputradioField {
&>div {
    &>label {
        font-size: 15px;
        margin-bottom: 12px;
        font-weight: 400;
    }
}
    & legend {
        font-size: 15px;
        margin-bottom: 12px;
        font-weight: 400;
    }

    & ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;

        @media(max-width:870px) {
            grid-template-columns: 1fr 1fr;
        }
    }
}