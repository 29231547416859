.compensationModal {
    & .compensationHeading {
        font-size: 22px;
        color: #000;
        line-height: 28px;
        margin-bottom: 30px;
    }

    & .modalForm {
        & .selectCompensation{
            margin: 30px 0;
        }
    }
}