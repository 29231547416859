@import '../../styles/customMediaQueries.css';
.profileSection {
  padding: 27px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  & .avatar {
    border: 5px solid var(--colorWhite);
    box-shadow: 0px 1px 3px 0px #e0e0e0;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin: 0 auto 20px;
    @media (--viewportMedium) {
      width: 120px;
      height: 120px;
    }
    @media (--viewportLarge) {
      width: 160px;
      height: 160px;
    }
    @media (--viewportLargeWithPaddings) {
      width: 193px;
      height: 193px;
    }
    & > span {
      font-size: 24px;
      font-weight: 400;
      @media (--viewportMedium) {
        font-size: 30px;
      }
      @media (--viewportLarge) {
        font-size: 40px;
      }
      @media (--viewportLargeWithPaddings) {
        font-size: 50px;
      }
    }
  }
  & > h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0px;
    color: var(--colorLabel);
    margin: 0;
    padding: 0;
    text-transform: capitalize;
    @media (--viewportLarge) {
      font-size: 24px;
      line-height: 36px;
    }
  }
  & .reviewSec {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    & .reviewStars {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      & > * {
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
    & > p {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      color: var(--colorLabel);
      margin: 0;
    }
  }
}
