@import '../../styles/customMediaQueries.css';

.root {
  background-color: #ffffff;
}

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    width: 100%;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

& .sidebar {
  width: 100%;
  max-width: 250px;
  border: 1px solid #00000017;
  box-shadow: 5px 1px 3px 0px #e0e0e01c;
  background-color: var(--colorWhite);
  position: absolute;
  left: -1000px;

  @media (--viewportMedium) {
    position: relative;
    left: 0;
  }

  @media (--viewportLarge) {
    max-width: 300px;
  }

  @media (--viewportLargeWithPaddings) {
    max-width: 340px;
  }

  &.sidebarMobile {
    left: 0px;
    z-index: 9;
    height: 100%;
    overflow-y: auto;
    top: 0;
  }
}

.editListingPageWrapper {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  background-color: #ffffff;

  @media (--viewportMedium) {
    padding: 0 24px;
    flex-direction: row;
    margin: 0px auto 60px;
  }

  & .sidebar {
    width: 100%;
    max-width: 250px;
    border: 1px solid #00000017;
    box-shadow: 5px 1px 3px 0px #e0e0e01c;
    background-color: var(--colorWhite);
    position: absolute;
    left: -1000px;

    @media (--viewportMedium) {
      position: relative;
      left: 0;
    }

    @media (--viewportLarge) {
      max-width: 300px;
    }

    @media (--viewportLargeWithPaddings) {
      max-width: 340px;
    }

    &.sidebarMobile {
      left: 0px;
      z-index: 9;
      height: 100%;
      overflow-y: auto;
      top: 0;
    }
  }

  & .mainContent {
    @media (--viewportMedium) {
      width: calc(100% - 250px);
      padding: 14px 0 0px 14px;
    }

    @media (--viewportLarge) {
      width: calc(100% - 300px);
      padding: 24px 0 0px 24px;
    }

    @media (--viewportLargeWithPaddings) {
      width: calc(100% - 340px);
    }

    & .pageTitle {
      color: var(--marketplaceColor);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      margin-bottom: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (--viewportMedium) {
        font-size: 15px;
        font-weight: 400;
      }

      & .menuIcon {
        display: inline-flex;

        @media (--viewportMedium) {
          display: none;
        }

        & path {
          fill: var(--colorBlack);
        }
      }
    }
  }
}

.profileContent {
  border: 1px solid #0000000f;
  background-color: var(--colorWhite);
  padding: 24px;
  float: left;
  width: 100%;

  @media (--viewportLarge) {
    padding: 36px;
  }
}

.BuyTopSection {
  & .buyHeading {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    color: #000;
  }

  & .buySubdetail {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #000;
  }
}

.buttonTopWrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  background-color: #F6F6F6;
  padding: 14px;
  border: 1px solid #f6f6f6;
  margin-top: 10px;
  flex-wrap: wrap;

  & .setButton {
    background-color: #054F31;
    padding: 24px 22px;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    color: #ffffff;
    border-radius: 10px;
  }

  & .currentButton {
    border: 1px solid #DDDDDD;
    background-color: #DDDDDD;
    padding: 13px 12px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;

    & .currentCompemtation {
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: left;
      color: #000000;
      border-radius: 5px;
    }

    & .compentationPercentage {
      background-color: #054F31;
      color: #fff;
      display: inline-block;
      padding: 10px;
      border-radius: 10px;
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      text-align: left;

    }
  }
}

.tabContainer {
  display: flex;
  gap: 30px;
  margin: 18px;
  align-items: center;
  width: 100%;
  overflow-x: auto;

  & .activeTab {
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #054F31;
    padding-bottom: 5px;
    border-bottom: 2px solid #054F31;

    @media(max-width:1024px) {
      font-size: 16px;
    }
  }

  & .inactiveTab {
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: #DDDDDD;
    padding-bottom: 5px;

    @media(max-width:1024px) {
      font-size: 16px;
    }
  }
}

.tabDetails {
  background-color: #ffffff;
  box-shadow: 0px 4px 30px 0px #00326D1A;
  padding: 20px;
}

.topImageWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  flex-wrap: wrap;

  @media(max-width:768px) {
    gap: 16px;
  }

  & .imageSection {
    display: flex;
    gap: 15px;
    align-items: center;

    @media(min-width:768px) {
      margin-left: 30px;
    }

    & img {
      height: 60px;
      width: 60px;
      border-radius: 100%;
    }

    & .profileName {
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      text-align: center;
      color: #000000;
    }
  }

  & .profileCompentation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    position: relative;

    &::after {
      content: "";
      background-color: #0000004D;
      left: -17px;
      position: absolute;
      width: 2px;
      height: 40px;
      top: 17px;
    }

    & .leftHeading {
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      text-align: left;
      color: #0000004D;
    }

    & .rigthDetail {
      background-color: #00dc495a;
      color: #00AB30;
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: center;
      width: 100%;
      padding: 8px;
      border-radius: 10px;
    }
  }
}

.providedCompentation {
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  padding: 12px;
  margin-top: 16px;
  border-top: 1px solid #0000001A;
  color: #618741;

}

.bioSection {
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: var(--marketplaceColor);
  padding: 6px;
  background: #00326D1A;
  border-radius: 10px;
}

.aboutSection {
  padding: 20px 0;
  border-bottom: 1px solid #0000001A;


  & span {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #C2C2C2;
    text-transform: capitalize;
  }

  &>div {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #C2C2C2;
  }
}

.contactButton {
  background-color: #054F31;
  padding: 12px 18px;
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  color: #ffffff;
  border-radius: 5px;
  width: fit-content;
  margin-top: 20px;
}

.spacer {
  margin: 30px 0;
  height: 1px;
  background-color: #DDDDDD;
}
.itemList {
  max-height: 800px;
  overflow-y: auto;
  padding: 20px;
  border: 1px solid #0000000f;
  background-color: var(--colorWhite);

  &::-webkit-scrollbar {
    width: 5px;
  }


  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }


  &::-webkit-scrollbar-thumb {
    background: var(--marketplaceColor);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}